import axios from 'axios'
import { Toast } from 'vant'

// 创建axios实例
const service = axios.create({
  baseURL: 'https://proxy.car-posthouse.cn/',
  // baseURL: 'http://47.99.212.29:8888/', 
  // baseURL: 'http://47.96.248.209:8888/', 

  timeout: 10000                  // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  if(config.auth != false){
    const Authorization = localStorage.getItem("Authorization")
    if (Authorization) {
      config.headers['Authorization'] = Authorization
    }
  }


  return config;
}, error => {
  Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code == 200) {
      return res
    } else {
      let err = response.message ? res.message : 'error'
      if (res.message == '授权失败请稍后再试') {
        WeixinJSBridge.call("closeWindow");
      }
      Toast(res.message)

      if (res.code == 10011 || res.code == 10007) {
        localStorage.removeItem('Authorization')
        const appid = sessionStorage.getItem("appid")

        // // 正式
        const redirect_uri = encodeURIComponent(
          "https://shopping.car-posthouse.cn/auths"
        );
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;

        // window.location.href = 'https://shopping.car-posthouse.cn/user?gid='+sessionStorage.getItem("gid");

      }

      return Promise.reject('request faild')
    }
  },
  error => {
    if (error.response.status == 401) {
      localStorage.removeItem('Authorization')
      const appid = sessionStorage.getItem("appid")

 
      // 正式
      const redirect_uri = encodeURIComponent(
        "https://shopping.car-posthouse.cn/auths"
      );
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;


      // window.location.href = 'https://shopping.car-posthouse.cn/user?gid='+sessionStorage.getItem("gid");

      // window.location.href = '/user'
    }
    Toast(error.response.data.message)
    return Promise.reject(error.response.data)
  }
)

export default service
